import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {
  UserDTO,
  UserDetailsDTO,
  UserFormDTO,
  UserListResponseDTO,
  UserLoginHistoryDTO,
} from '../dto/user.dto';
import { BaseService } from '../../../../core/services/base.service';
import { Observable } from 'rxjs';
import { UserTaskListDTO, UserTaskSummaryDTO } from '../dto/user-task.dto';
import { MainBaseService } from '../../../../core/services/main-base-service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends MainBaseService<
  UserListResponseDTO,
  UserFormDTO
> {
  protected apiUrl = environment.apiUrl + '/users';

  constructor(http: HttpClient) {
    super(http);
  }

  getLoginHistoryByUserId(
    userId: number,
    currentPage: number = 1,
    pageSize: number = 10
  ): Observable<UserLoginHistoryDTO[]> {
    let params = new HttpParams()
      .set('page', currentPage.toString())
      .set('page_size', pageSize.toString());

    return this.http.get<UserLoginHistoryDTO[]>(
      `${this.apiUrl}/${userId}/login-history`,
      { params }
    );
  }

  getUserTasksSummary(userId: number): Observable<UserTaskSummaryDTO> {
    return this.http.get<UserTaskSummaryDTO>(
      `${this.apiUrl}/${userId}/task-summary`
    );
  }

  getTaskByUserId(
    userId: number,
    sortField: string = '',
    sortOrder: string = 'asc',
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery: string,
    quickFilterKey?: string
  ): Observable<UserTaskListDTO[]> {
    let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder)
      .set('page', currentPage.toString())
      .set('page_size', pageSize.toString());

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    if (quickFilterKey) {
      params = params.set('quick_filter', quickFilterKey);
    }

    return this.http.get<UserTaskListDTO[]>(`${this.apiUrl}/${userId}/tasks`, {
      params,
    });
  }
}
